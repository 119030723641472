.AuthScene {
  background-color: #F8F9FB;
  height: 100%;

  .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);
    display: flex;
    flex-direction: column;
    align-items: center;

    button, .button {
      outline: none;
      height: 40px;
      background: linear-gradient(to bottom right, #5CCFFA, #4AACF8);
      color: white;
      font-size: 9pt;
      font-weight: bold;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100%;

      &.loading {
        cursor: default;
        background: linear-gradient(lightgrey, lightgrey)
      }
    }

    img {
      width: 140px;
      margin: 0 auto 30px auto;
    }

    h1 {
      font-size: 14pt;
      text-align: center;
      margin: 30px auto;
    }

    .error {
      font-size: 9pt;
      color: red;
      font-weight: bold;
    }

    .message {
      font-size: 9pt;
      color: #13689f;
      font-weight: bold;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 350px;

      input {
        margin: 10px 0;
        line-height: 35px;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        background-color: white;
        outline: none;
        -webkit-box-shadow: 0 0 0 100px white inset;
        padding: 0 10px;
      }
    }
  }
}