.Category {
  padding: 20px 40px 70px 40px;

  .deleteButton {
    height: 17.5px;
    padding: 0.7rem;
  }

  .upper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1em;
    padding: 20px 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);

    input[type=text] {
      outline: none;
      border: none;
      background-color: transparent;
      font-size: 12pt;
      font-weight: bold;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      width: 300px;
    }

    .arrows {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 20px;

      img {
        height: 12px;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .bin {
      cursor: pointer;
      height: 25px;
      width: 25px;
      margin: auto 0 auto 20px;
    }
  }

  .lower {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    gap: 20px;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}