@import './assets/stylesheets/bootstrap_settings';
@import '~bootstrap/scss/bootstrap';
@import './assets/stylesheets/bootstrap_overrides';

@import './assets/stylesheets/components/Checkbox';
@import './assets/stylesheets/components/Dividers';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

p, a, h1, h2, h3, h4, h5, h6, button {
  font-family: 'Roboto', sans-serif;
  color: #484848;
}

.btn {
  font-weight: bold;
  outline: none;
  cursor: pointer;

  &.blue {
    color: white;
    background: linear-gradient(to bottom, #58C6FA, #4AACF8);
  }

  &.red {
    color: white;
    background: linear-gradient(to bottom, rgba(252, 65, 65, 0.95), rgb(255, 50, 50));
  }

  &.white {
    color: #8F8F8F;
    border: solid 1px #E6E8EA;
  }
}

.deleteButton {
  font-size: 15px;
  padding: 0.5rem;
  background: #D8DADD;
  color: white;
  border-radius: 4px;

  &:hover {
    background: #FF0000;
  }
}

.d-grid {
  display: grid;
}

@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }

  .grid-cols-#{$i}.fixed-left {
    grid-template-columns: 2fr, repeat(#{$i - 1}, minmax(0, 1fr));
  }
}


