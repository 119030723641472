.Header {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 20px 40px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  input[type=text] {
    font-size: 13pt;
    font-weight: bolder;
    line-height: 40px;
    margin: 0 40px 0 0;
    height: 40px;
    border: none;
    outline: none;
  }

  .deleteButton {
    height: 17.5px;
    padding: 0.7rem;
  }

  .save {
    margin-left: auto;
    display: flex;

    p {
      color: red;
      font-weight: bold;
      font-size: 10pt;
      text-align: right;
      margin-right: 20px;
      margin-bottom: 0;
    }

    .btn {
      min-width: 100px;
    }
  }
}