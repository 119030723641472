.PageLayout {
  display: grid;
  grid-template-columns: 260px auto;
  height: 100%;
  overflow: hidden;

  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-right: solid 1px #E6E8EA;
    box-shadow: 2px 0px 50px #EBEBED;
    z-index: 2;
    min-height: 100vh;
    background: white;

    img {
      height: 60px;
      margin-right: auto;
      object-fit: contain;
    }

    .nav {
      display: flex;
      flex-direction: column;

      .nav-section {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 10px;

        .menu-nav {
          display: flex;
          justify-content: space-between;
        }

        .arrows {
          margin-top: auto;
          margin-bottom: auto;

          img {
            height: 12px;
            cursor: pointer;
            margin-left: 10px;
          }
        }

        h4 {
          font-weight: bold;
          font-size: 10pt;
          color: #8F8F8F;
        }

        button {
          background-color: none;
          border: none;
          font-weight: bolder;
          outline: none;
        }

        p {
          margin: 0px;
          margin-left: auto;
        }

        a, button {
          font-weight: bolder;
          font-size: 11pt;
          line-height: 60px;
          text-decoration: none;
          display: flex;
          position: relative;
          margin: 0px -40px;
          padding: 0px 25px 0px 40px;

          &.active {
            background-image: linear-gradient(to bottom right, #5CCFFA, #4AACF8);
            color: white;
          }

          .logo {
            position: absolute;
            width: 20px;
            right: 25px;
          }
        }
      }
    }
  }

  .content {
    background-color: #F8F9FB;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .PageLayout {
    grid-template-columns: auto;
    width: 100vw;

    .sidebar {
      display: none;
    }
    .sidebar.open {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}