.selection-menu {
  .section-title {
    font-weight: bold;
    font-size: 12pt;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 5px;
    margin-bottom: 0;

    li {
      padding: 0.25rem;
      border-radius: 4px;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;

      label {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
      }

      a {
        font-weight: bold;
        color: #37BEFF !important;

        &:hover {
          text-decoration: underline !important;
          cursor: pointer;
        }
      }

      &.checked {
        background-color: #E7F8EE;
      }
    }
  }

}