.Item {
  background-color: white;
  width: 300px;
  box-shadow: 8px 16px 24px rgba(154, 157, 165, 0.3);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: fit-content;

  .header {
    display: flex;
    align-items: center;

    .wrapper {
      position: relative;
      flex: 1 1 auto;
      display: flex;
      align-items: center;

      .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #55c1fa;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: white;
        position: absolute;
        right: 0;
        transform: translateX(-50%);
        cursor: default;
      }

      input {
        outline: none;
        border: solid 1px #E6E8EA;
        border-radius: 4px;
        margin: 0 10px 0 0;
        line-height: 35px;
        flex-grow: 1;
        font-size: 10pt;
        padding-left: 10px;
      }
    }

    img {
      height: 35px;
      cursor: pointer;
      transform: rotate(-90deg);
    }
  }

  .allergens {
    margin-top: 18px;

    p {
      margin-left: 5px;
      font-size: 9pt;
      color: #8F8F8F;
    }

    .selector {
      display: flex;

      .button {
        background-color: #F8F9FB;
        border-radius: 4px;
        display: flex;
        flex-grow: 1;
        padding: 10px 15px;
        cursor: pointer;
        margin-right: 10px;

        p {
          margin-top: 2px;
          margin-bottom: 2px;
          flex-grow: 1;
          font-size: 11pt;
          color: black;
        }

        img {
          margin-top: auto;
          margin-bottom: auto;
          height: 14px;
        }
      }

      img {
        margin-top: auto;
        margin-bottom: auto;
        height: 35px;
        cursor: pointer;
      }
    }
  }
}