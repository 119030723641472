@media screen and (max-width: 1199px) {
  .modal-extra-wide {
    width: 97vw !important;
    max-width: 97vw !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .modal-extra-wide {
    width: 75vw !important;
    max-width: 75vw !important;
  }
}

@media screen and (min-width: 1400px) {
  .modal-extra-wide {
    width: 65vw !important;
    max-width: 65vw !important;
  }
}


.AllergensPopup {
  background-color: white;
  margin-left: 0;
  min-height: 100px;
  font-size: 13px;
  //width: 80vw;
  //border: solid 1px rgba(0, 0, 0, 0.05);
  //border-radius: 4px;
  //box-shadow: 0 10px 20px 3px rgba(0, 0, 0, 0.1);

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #E6E8EA;
    padding: 12px 15px;
    margin: 0 0 1em;

    .actions {
      display: flex;
      gap: 0.5em;
    }

    .title {
      font-weight: 500;
      font-size: 15pt;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .deleteButton {
        height: 17.5px;
        padding: 0.7rem;
        margin-left: 1.4rem;
      }

      img {
        height: 25px;
        margin-left: 0.8rem;
      }
    }
  }

  .allergens-list {
    display: flex;
    flex-direction: column;
    //padding: 10px 15px 10px 15px;

    .allergens-container {
      display: flex;
      flex-direction: column;
      gap: .2rem;
    }

    .section-title {
      font-size: 12pt;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .allergen-row {
      &.last-clicked {

        .option-cell.active {
          box-shadow: 0 0 2px 3px #beceff;
        }
      }
    }


    .allergen-row, .allergen-header-row {
      align-items: stretch;
      padding: 0.25rem 0.25rem;
      border-radius: 6px;
      gap: .2rem;

      .blank-cell, .option-cell {
        text-align: center;
        padding: 0.2rem 0.2rem;
        border-radius: 6px;
      }

      .option-cell {
        //background: #F8F9FB;
        background: #F8F9FB;
        border: 2px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;

        svg.fa-circle {
          color: #D8DADD;
        }

        svg {
          color: white;
        }

        &.red {
          color: #D8DADD;

          &.active {
            svg {
              color: #F43D3D
            }

            svg.fa-circle {
              color: white;
            }

            &:hover {
              //background: #F8F9FB;
              border: 2px solid transparent;

              svg {
                //color: white;
                color: #F43D3D;
              }

              svg.fa-circle {
                //color: #F43D3D;
                color: white;
              }
            }

            color: #F43D3D;
            background: #F43D3D;
          }

          &:hover {
            color: white;
            border: 2px solid #F43D3D;

            svg.fa-circle {
              color: #F43D3D;
            }
          }
        }

        &.orange {
          color: #D8DADD;

          &.active {
            svg {
              color: #FF9900
            }

            svg.fa-circle {
              color: white;
            }

            &:hover {
              //background: #F8F9FB;
              border: 2px solid transparent;

              svg {
                //color: white;
                color: #FF9900;
              }

              svg.fa-circle {
                //color: #FF9900;
                color: white;
              }
            }

            color: #FF9900;
            background: #FF9900;
          }

          &:hover {
            color: white;
            border: 2px solid #FF9900;

            svg.fa-circle {
              color: #FF9900;
            }
          }
        }

        &.blue {
          color: #D8DADD;

          &.active {
            svg {
              color: #04ADFF
            }

            svg.fa-circle {
              color: white;
            }

            &:hover {
              //background: #F8F9FB;
              border: 2px solid transparent;

              svg {
                //color: white;
                color: #04ADFF;
              }

              svg.fa-circle {
                //color: #04ADFF;
                color: white;
              }
            }

            color: #04ADFF;
            background: #04ADFF;
          }

          &:hover {
            color: white;
            border: 2px solid #04ADFF;

            svg.fa-circle {
              color: #04ADFF;
            }
          }
        }

        &.green {
          color: #D8DADD;

          &.active {
            svg {
              color: #13BF5B
            }

            svg.fa-circle {
              color: white;
            }

            &:hover {
              //background: #F8F9FB;
              border: 2px solid transparent;

              svg {
                //color: white;
                color: #13BF5B;
              }

              svg.fa-circle {
                //color: #13BF5B;
                color: white;
              }
            }

            color: #13BF5B;
            background: #13BF5B;
          }

          &:hover {
            color: white;
            border: 2px solid #13BF5B;

            svg.fa-circle {
              color: #13BF5B;
            }
          }
        }
      }

      p {
        //font-size: 12pt;
        font-weight: normal;
        margin: auto 0;
      }

      select {
        padding: 3px 10px;
        border-radius: 3px;
        border: solid 1px #E6E8EA;
        background-color: white;
        font-size: 9pt;
        outline: none;
      }
    }
  }
}