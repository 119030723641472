@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --indeterminate: #03C9FF;
    --indeterminate-border: #04ADFF;
    --active: #13BF5B;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #D9D9D9;
    --active-border: #10A24D;
    --border-hover: #13BF5B;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 2px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    width: 21px;

    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      //transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }

    &:indeterminate {
      --b: var(--indeterminate);
      --bc: var(--indeterminate-border);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    &:checked {
      --b: var(--active);
      --bc: var(--active-border);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;

      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }

      & + label {
        cursor: not-allowed;
      }
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }

    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }


    &:after {
      opacity: var(--o, 0);
    }

    &:checked, &:indeterminate {
      --o: 1;
    }

    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 0.5rem;
    }
  }
  input[type='checkbox'] {
    border-radius: 6px;

    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 6px;
      top: 3px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --r: 43deg;
    }

    &:indeterminate::after {
      --r: 0deg;
      border-right: 0;
      top: 1px;
    }

    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 0.5rem;
      margin-bottom: 0 !important;
    }
  }

  input[type='radio'] {
    border-radius: 50%;

    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }

    &:checked {
      --s: .5;
    }
  }
}
